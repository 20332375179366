import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, from, lastValueFrom, throwError} from 'rxjs';

import {Injectable} from '@angular/core';
import {Auth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {UiService} from './common/ui.service';


@Injectable()
/**
 * Intercepts Http Request
 */
export class AuthHttpInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthService}authService
   * @param {Router}router
   */
  constructor(
    private auth: Auth, private router: Router,
    private uiService: UiService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // convert promise to observable using 'from' operator
    return from(this.handle(req, next)).pipe(
        catchError((err, caught) => {
          if (err.error?.error) {
            this.uiService.showToast(err.error?.error);
            return throwError(() => Error(err.error?.error ?? ''));
          }
          this.uiService.showToast(JSON.stringify(err));
          return throwError(() => err);
        }),
    );
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = await this.auth.currentUser?.getIdToken() ?? '';

    const authReq = req.clone({
      setHeaders: {
        Authorization: 'Bearer '+ authToken,
      },
    });

    return await lastValueFrom(next.handle(authReq));
  }
}
